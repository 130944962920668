<template>
      <div class="current1" >
          
          <el-form label-position="right" label-width="116px" ref='ruleForm'  inline  :model="ruleForm" :rules="rules">

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="用户名称:" prop="cname">
                    <el-input style="width:300px" v-model="ruleForm.cname" ></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="用户简称:" prop="jname" >
                    <el-input style="width:300px" v-model="ruleForm.jname" ></el-input>
                </el-form-item>
            </el-col>

            <!-- <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="网站简称:" prop="wname" >
                    <el-input style="width:300px"  v-model="ruleForm.wname" ></el-input>
                </el-form-item>
            </el-col> -->
            
            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="微信客服:" prop="wxkf" >
                    <el-input style="width:300px"  v-model="ruleForm.wxkf" ></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="管理员名称:" prop="gname" >
                    <el-input style="width:300px"  v-model="ruleForm.gname" ></el-input>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="管理员电话:" prop="phone" >
                    <el-input maxlength="11" style="width:300px"  v-model="ruleForm.phone" oninput="value=value.replace(/[^\d.]/g,'')" ></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="登录账号:" prop="loginname" >
                    <el-input style="width:300px" :disabled ="disabled" v-model="ruleForm.loginname" ></el-input>
                </el-form-item>
            </el-col>

            <!-- <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="密码:" prop="pwd" >
                    <el-input type="password" style="width:300px" :disabled ="disabled" v-model="ruleForm.pwd" ></el-input>
                </el-form-item>
            </el-col> -->

            <el-col>
                <el-form-item label="用户性质:"  prop="mtype">
                    <template>
                    <el-select  :disabled="disabled" style="width:300px"  v-model="ruleForm.mtype" placeholder="请选择">
                        <el-option
                        v-for="item in storeNature"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    </template>
                </el-form-item>
            </el-col>
        
            <el-col>
                <el-form-item label="用户级别:"  prop="mlvl">
                    <template>
                    <el-select  :disabled="disabled" style="width:300px" v-model="ruleForm.mlvl" placeholder="请选择">
                        <el-option
                        v-for="item in storeLevel"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    </template>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item label="用户城市:"  prop="address1" >
                <div>
                
                        <el-cascader :disabled="disabled" v-model='ruleForm.address1' style="width:300px" :options="address" placeholder="选择城市"
                    @change="changeaddress1" clearable></el-cascader>

                </div>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item style="font-size:15x; text-align:right" label="详细地址:" prop="address" >
                    <el-input style="width:300px" v-model="ruleForm.address" ></el-input>
                </el-form-item>
            </el-col>

            <el-col >
                    <el-form-item label="用户简介:"  prop="address">
                        <el-input maxlength="1000" style="width:582px" type="textarea" v-model="ruleForm.content" :rows="5"></el-input>
                    </el-form-item>
            </el-col>

            <el-col :span="24"> 
                      <el-form-item label="公众号二维码:" prop="qrcode">
                        <el-upload
                        class="upload-demo"
                        action="#" 
                        :http-request="uploadImgs" 
                        multiple
                        :limit="1"
                        style="width:585px;height:32px;display:flex"
                        ref ="upload1"
                        >
                        <diV>
                            <el-button :size="tabsize"  type="primary" @click="fileDelete">点击上传</el-button>
                            <!-- &nbsp;&nbsp;&nbsp;
                            <h3 style="color:red;white-space:nowrap" v-if="vif1">上传图片小于1M</h3>         -->

                        </diV>
                           
                    </el-upload>
              </el-form-item>

                <div>
                     <el-image style="width:200px;height:200px;margin-left: 10px;" v-if="ruleForm.qrcode" :src="ruleForm.qrcode" :fit="fit"></el-image>
                </div>
            </el-col>

            <p class="btns">
                <el-button v-if="formShow1" :size="tabsize" type="primary"  @click="onSubmit('ruleForm')">保存</el-button>
              </p>

        </el-form>
      </div>

</template>

<script>

    import OSS from "ali-oss";
        const client = new OSS({
        region: "oss-cn-beijing",
        accessKeyId: "LTAIuIJwjQDNloUw",
        accessKeySecret: "GohgvAzqsYA7pTWeceXcftLVy9TMPH",
        bucket: "bwdev"
    });
    export default {
      created(){

            // this.storeid = this.$route.query.id;//用户id   

           this.$api.detailes.getAreaList({
               
            }).then(res => {
                this.address = res.obj.options
            })//获取位置信息

          
          this.$api.detailes.getById({
            //   id: this.storeid

          }).then(res => {
            //this.vif = false
            this.ruleForm = res;
            this.address1 = JSON.stringify(this.ruleForm)
            this.address1 = JSON.parse(this.address1)
            // this.ruleForm.qrcode = this.ruleForm.qrcode+''
            this.ruleForm.address1 =[],
            //this.vif = true
            // this.fileSeeVif();
            this.ruleForm.address1.push(this.address1.add1)
            this.ruleForm.address1.push(this.address1.add2)
            this.ruleForm.address1.push(this.address1.add3)

          }).catch((error) => {
              console.log(error);
          })
      },
      data(){
        return {
          fit:"contain",
          storeid:'',
          inputpersonid:'',//暂时为死数据
          tableData:[],
          sumData:[],
          address:[],
          address1:[],
          disabled:true,
          vif:true,
          vif1:true,
          timer:null,
          formShow1:true,
          ruleForm: {},

          storeNature:[
                    {
                        value:1,
                        label:'旗舰店'
                    },
                    {
                        value:2,
                        label:'体验店'
                    },
                    {
                        value:3,
                        label:'医院'
                    },
                    {
                        value:4,
                        label:'视光中心'
                    },
                    {
                        value:5,
                        label:'视保店'
                    },
                     {
                        value:6,
                        label:'智能体验馆'
                    },
                ],
                storeLevel:[
                    {
                        value:1,
                        label:'A'
                    },{
                        value:2,
                        label:'B'
                    },{
                        value:3,
                        label:'C'
                    },{
                        value:4,
                        label:'D'
                    },{
                        value:5,
                        label:'E'
                    },
                ],
           rules: {
            cname: [{
                        required: true,
                        message: '请输入用户名称',
                        trigger: 'blur'
                    }],
                    jname: [{
                        required: true,
                        message: '请输入用户简介',
                        trigger: 'blur'
                    }],
                    gname: [{
                        required: true,
                        message: '请输入管理员',
                        trigger: 'blur'
                    }],
                    phone:[{
                        required: true,
                        pattern:  /^1[3456789]\d{9}$/,
                        message: '请输入正确的联系电话',
                        trigger: 'blur'
                    }],
                    mtype: [{
                        required: true,
                        message: '请选择性质',
                        trigger: 'change'
                    }],
                    mlvl: [{
                        required: true,
                        message: '请选择用户级别',
                        trigger: 'change'
                    }],
                    loginname: [{
                        required: true,
                        message: '请输入登录账号',
                        trigger: 'blur'
                    }],
                    pwd: [{
                        required: true,
                        message: '请输入登录密码',
                        trigger: 'blur'
                    }],
                    address1: [{
                        required: true,
                        message: '请选择城市',
                        trigger: 'change'
                    }],
                    address: [{
                        required: true,
                        message: '请输入具体位置',
                        trigger: 'blur'
                    }],
                    wname: [{
                        required: true,
                        message: '请输入网站简称',
                        trigger: 'blur'
                    }],
                    content: [{
                        required: true,
                        message: '请输入简介',
                        trigger: 'blur'
                    }],
                    qrcode: [{
                        required: true,
                        message: '请上传图片',
                        trigger: 'blur'
                    }],
                    wxkf: [{
                        required: true,
                        message: '请输入客服微信',
                        trigger: 'blur'
                    }],
            },//非空验证
        }
      },
      // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
              return this.$store.state.tabsize
          }
      },

      methods: {
          onSubmit(formName){
            var that = this;
            this.$refs[formName].validate((valid) => { 
                if(valid){
                    let url
                    let str
                    url = this.$api.detailes.update
                    str = '修改'
                    url(that.ruleForm).then(res => {

                        if(res.status == 0){
                            this.$notify.error({
                                title: 'error',
                                message: res.msg,
                                showClose: false,
                                duration: 2000,
                            });
                        }else{
                            this.$notify.success({
                                title: 'success',
                                message: str+'成功',
                                showClose: false,
                                duration: 2000,
                            });
                            this.$router.push({
                                path: "/detaSee",
                                query: {},
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                        return false
                    })
                }else{
                    this.$notify.error({
                        title: 'error',
                        message: '请确定必填数据已输入',
                        showClose: false,
                        duration: 2000,
                    });
                    return false
                }
            })
            },
        changeaddress1: function(val){
                 if(val.length >=3){
                    this.ruleForm.add1 = val[0] || ''
                    this.ruleForm.add2 = val[1] || ''
                    this.ruleForm.add3 = val[2] || ''
                 }else if 
                 (val.length >=2){
                    this.ruleForm.add1 = val[0] || ''
                    this.ruleForm.add2 = val[1] || ''
                    this.ruleForm.add3 =  ''
                 }else if (val.length >=1){
                    this.ruleForm.add1 = val[0] || ''
                    this.ruleForm.add2 =''
                    this.ruleForm.add3 = ''
                 }else{
                     this.ruleForm.add1 = ''
                    this.ruleForm.add2 =''
                    this.ruleForm.add3 = ''
                 }
                 
                 this.ruleForm.address1 = "表单验证通过//无用数据 只用于vue表单验证"
        },
        uploadImgs(files) {    
                let fileArray = [];
                this.vif1 = false;

                const fileType = files.file.type.split('/')[0] === 'image';
                if (!fileType) {
                  this.$notify.error({
                    title: 'error',
                    message: '只能上传图片',
                    showClose: false,
                    duration: 2000,
                });
                  return;
                }

                if (Array.isArray(files)) {
                    fileArray.push(...files);
                } else {
                    fileArray.push(files);
                }
                let resultArray = [];
                let put = async file => {
                   try {
                        let date = new Date();
                        let yaer = date.getFullYear().toString();
                        let month = (date.getMonth() + 1).toString();
                        let day = date.getDate().toString();
                        let hour = date.getDate().toString();
                        let min = date.getMinutes().toString();
                        let seconds = date.getSeconds().toString();
                        let tmp = yaer + month + day + hour + min + seconds + file.file.name;
                        let result = await client.put(tmp, file.file);
                        if (result.res.status == 200) {

                            this.vif=false;

                            this.ruleForm.qrcode = result.res.requestUrls[0]

                            this.vif=true;
                            return result.url
                        } else {
                            that.$notify.error("上传失败")
                        }
                    } catch (error) {

                    }
                    
                };
                fileArray.forEach(file => {
                    put(file);
                });
            },

            fileDelete: function(files){
                this.$refs.upload1.clearFiles();
                this.vif1 = true
            },

            // fileSeeVif(){
            //     this.timer = setInterval(() => {
            //         console.log(123)
            //         this.vif = true
            //         this.vif =false
            //         this.vif = true
            //     },1000)
            // }
        },
      }
</script>

<style scoped>

.current1{
    position: relative;
    width: 98%;
    height: 98%;
    overflow: auto;
    margin: 1%;
  }
  .current-div1{
    height: auto;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    color: #666666;
    justify-content: center;
  }
  .el-input__inner{
      width: 180%;
  }
 

  #top-a{
      display: flex;
      justify-content: space-between;
  }

  .xq{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 20px;
   }

   .usernum{
        display: flex;
        justify-content: space-around;
        margin-left: 20px;
   }

   .usernum span{
        background-color: #409eff;
        display: flex;
        flex-direction: column;
        width: 15%;
        height: 60px;
        color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        margin-bottom: 20px;
   }

   .button{
       height: 28px;
       display: flex;
       margin-top: 17px;
       margin-left: 30px;
   }

</style>
